.pseudo-absolute {
  position: $abs;
  content: '';
}
.absolute {
  position: $abs;
}
.relative {
  position: $rel;
}
.hidden {
  opacity: 0;
  visibility: hidden;
}
.visible {
  opacity: 1;
  visibility: visible;
}
.d--block {
  display: block;
}
.i-block {
  display: inline-block;
}
.bdrs-3 {
  border-radius: 3px;
}
.transition03 {
  transition: all .3s;
}
.full-width {
  width: 100%;
}
//---------------------------------

@-webkit-keyframes fadeInRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(20px);
    transform: translateX(20px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
@keyframes fadeInRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(20px);
    -ms-transform: translateX(20px);
    transform: translateX(20px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
  }
}
.fadeInRight {
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight;
}
.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.side-line:before {
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  height: 65%;
  width: 1px;
  background: #DDDDE8;
}
.img-fluid-inside img {
  width: 100%;
  height: auto;
}
.img-miniature-inside img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.bg-main {
  background: #f5f6fa;
}
.bottom-main-line {
  position: relative;

  &:after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 1px;
    width: 100%;
    background: #34CCA9;
  }
}
.h1-v1 {
  font-size: 26px;
}
.ff-sans-bold {
  font-family: 'DMSans-Bold', sans-serif;
}

.color-black {
  color: $clr !important;
}
.color-dark-gray {
  color: $clr6 !important;
}
.link-mask {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
}
.dm-sans-regular {
  font-family: DMSans-Regular, sans-serif !important;
}
.dm-sans-bold {
  font-family: DMSans-Bold, sans-serif !important;
}
.dm-sans-medium {
  font-family: DMSans-Medium, sans-serif !important;
}
.pt-5_1 {
  padding-top: 80px !important;
}
.py-5_3 {
  padding-top: 100px;
  padding-bottom: 100px;
}
.pl-5_1 {
  padding-left: 89px !important;
}
.pl-2_1 {
  padding-left: 10px !important;
}
.pt-4_2 {
  padding-top: 41px !important;
}
.pl-4_2 {
  padding-left: 31px !important;
}
.pl-4_1 {
  padding-left: 35px !important;
}
.px-6 {
  padding-left: 31px !important;
  padding-right: 31px !important;
}
.py-6 {
  padding-top: 26px !important;
  padding-bottom: 26px !important;
}
.pb-4_2 {
  padding-bottom: 33px !important;
}
.py-3_1 {
  padding-top: 19px !important;
  padding-bottom: 19px !important;
}
//------
.py-3_2 {
  padding-top: 40px !important;
  padding-bottom: 40px !important;
}
.px-3_2 {
  padding-left: 56px !important;
  padding-right: 56px !important;
}
//------
.pt-6 {
  padding-top: 21px !important;
}
.pt-1_2 {
  padding-top: 12px !important;
}
.pl-2_2 {
  padding-left: 13px !important;
}
.pl-3_1 {
  padding-left: 19px !important;
}
.pl-3_2 {
  padding-left: 20px !important;
}
.pl-3_3 {
  padding-left: 31px !important;
}
.pl-3_4 {
  padding-left: 40px !important;
}
.pl-3_5 {
  padding-left: 29px !important;
}
.pl-3_6 {
  padding-left: 42px !important;
}
.pl-3_7 {
  padding-left: 55px !important;
}
.pl-3_8 {
  padding-left: 51px !important;
}
.pl-3_9 {
  padding-left: 48px !important;
}
.pl-6_1 {
  padding-left: 62px !important
}
.lh-1_3 {
  line-height: 1.3 !important;
}
.mb-1_1 {
  margin-bottom: 1px !important;
}
.bg-blue {
  background: #3B5998 !important;
}
.bg-blue-light {
  background: #1DA1F2 !important;
}
.text-blue {
  color: #218AEF !important;
}
.border-gray-light {
  border: 1px solid #E4E4EB !important;
}
.text-gray {
  color: #7E7E84 !important;
}
.mr-1_1 {
  margin-right: 5px !important;
}
.pr-1_9 {
  padding-right: 6px !important;
}
.mt-2_2 {
  margin-top: 19px !important;
}
.mr-3_1 {
  margin-right: 15px !important;
}
.mr-3_2 {
  margin-right: 7px !important;
}
.mr-4_1 {
  margin-right: 33px !important;
}
.content-wrap-v1 {
  margin-bottom: 22px;
}
.mb-n1_2 {
  margin-bottom: -0.35rem;
}
.mb-1_2 {
  margin-bottom: 0.45rem;
}
.mb-2_1 {
  margin-bottom: 11px !important;
}
.mr-2_1 {
  margin-right: 8px !important;
}
.mr-5_2 {
  margin-right: 25px !important;
}
.mb-1_3 {
  margin-bottom: 6px !important;
}
.mb-3_2 {
  margin-bottom: 14px !important;
}
.mb-3_3 {
  margin-bottom: 18px !important;
}
.mb-4_1 {
  margin-bottom: 42px !important;
}
.mb-4_2 {
  margin-bottom: 44px !important;
}
.mb-5_5 {
  margin-bottom: 48px !important;
}
.mb-4_3 {
  margin-bottom: 25px !important;
}
.mb-4_4 {
  margin-bottom: 33px !important;
}
.mb-4_5 {
  margin-bottom: 36px !important;
}
.py-6_1 {
  padding-top: 58px !important;
  padding-bottom: 58px !important;
}
.px-6_1 {
  padding-left: 40px !important;
  padding-right: 40px !important;
}
.mb-4_9 {
  margin-bottom: 38px !important;
}
.mb-4_6 {
  margin-bottom: 27px !important;
}
.mb-4_7 {
  margin-bottom: 23px !important;
}
.mb-5_1 {
  margin-bottom: 65px !important;
}
.mb-5_2 {
  margin-bottom: 53px !important;
}
.mb-5_7 {
  margin-bottom: 50px !important;
}
.mb-5_4 {
  margin-bottom: 58px !important;
}
.mb-4_8 {
  margin-bottom: 25px !important;
}
.fz-min {
  font-size: 13px !important;
}
.fz-min-second {
  font-size: 14px !important;
}
.fz-small {
  font-size: 12px !important;
}
.fz-main {
  font-size: 16px !important;
}
.ls-0_5 {
  letter-spacing: 0.5px !important;
}
.mb-10 {
  margin-bottom: 189px !important;
}
.mb-5_3 {
  margin-bottom: 56px !important;
}
.offset-1_2 {
  margin-left: 4.2%;
}
.cursor-default {
  cursor: default !important;
}
.pb-6 {
  padding-bottom: 80px !important;
}
.pb-7 {
  padding-bottom: 45px !important;
}
.py-from-3_3 {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}
.py-2_9 {
  padding-top: 14px !important;
  padding-bottom: 14px !important;
}
.pb-5_2 {
  padding-bottom: 77px !important;
}
.p-single-use-1 {
  padding: 18px 16px 11px 16px!important;
}
.p-single-use-2 {
  padding: 31px 24px 48px 25px!important;
}
.text-dark-grey {
  color: #A1A1A9 !important;
}
.text-secondary-grey {
  color: #8F8F9F;
}
.col-1_5, .col-1_4, .col-6_2 {
  width: 100%;
}
.col-1_5 {
  flex: 0 0 14.1%;
  max-width: 14.1%;
  padding: 0 15px;
}
.col-1_4 {
  flex: 0 0 9.8%;
  max-width: 9.8%;
  padding: 0 15px;
}
.col-6_3 {
  @include respond-to(md-screens) {
    flex: 0 0 44%;
    max-width: 44%;
    padding-left: 0;
    padding-right: 0;
  }
}
.col-6_2 {
  @include respond-to(md-screens) {
    flex: 0 0 51.5%;
    max-width: 51.5%;
    padding-left: 0;
    padding-right: 0;
  }
}
.hero-main__first-col {
  flex: 0 0 18%;
  max-width: 18%;
  padding-left: 19px;
}
.hero-main__second-col {
  flex: 0 0 21%;
  max-width: 21%;
}
.hero-main__third-col {
  flex: 0 0 13%;
  max-width: 13%;
}
.hero-main__fourth-col, .hero-main__seventh-col {
  flex: 0 0 12%;
  max-width: 12%;
}
.hero-main__fifth-col {
  flex: 0 0 11%;
  max-width: 11%;
  padding-left: 18px;
}
.hero-main__sixth-col {
  flex: 0 0 9%;
  max-width: 9%;
}
.hero-main__eighth-col {
  flex: 0 0 3.3%;
  max-width: 3.3%;
}
.bg-light-gray {
  background: rgba(243, 244, 248, 0.5) !important;
}
.text-decoration {
  text-decoration: underline !important;
}
.zIndex {
  z-index: 5 !important;
}
.bg-gray-2 {
  background: #DEE7EF !important;
}
.border-radius-max {
  border-radius: 8px !important;
}
.lh-1_7 {
  line-height: 1.7 !important;
}
.border-radius-min {
  border-radius: 4px !important;
}
.box-shadow-main {
  box-shadow: 0 4px 8px rgba(28, 28, 32, 0.04) !important;
}
.border-gray-2 {
  border: 1px solid #DDDDE8 !important;
}
.bg-gray-3 {
  background: #F9F9FA !important;
}
.bg-gray-4 {
  background: #F6FAFE !important;
}
.after-left-line-2:after {
  content: "";
  position: absolute;
  top: 15%;
  right: 0;
  width: 1px;
  height: 60%;
  background: #DDDDE8;
}
.position-initial {
  position: initial !important;
}
.hover-svg svg path,
.hover-svg, .hover-link {
  transition: all .2s ease;
}
.hover-svg:hover svg path {
  fill: #fff;
}
.hover-svg:hover, .hover-link:hover {
  background: #218AEF !important;
}
.hover-link:hover {
  color: #fff !important;
}
.breadcrumb-item-main a:hover {
  text-decoration: underline !important;
}
.box-shadow-second {
  box-shadow: 0px 4px 8px rgba(28, 28, 32, 0.04);
}
.border-right-0 {
  border-right: 0 !important;
}
.border-right-gray {
  border-right: 1px solid #dddde8!important;
}
.text-red {
  color: #EB1111;
}

@media(max-width: 1199px) {
  .p-from-xxl-3_2 {
    padding: 20px !important;
  }
  .mb-from-xxl-3_2 {
    margin-bottom: 30px !important;
  }
  .pl-from-xl-3 {
    padding-left: 15px !important;
  }
  .col-from-xl-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .mr-from-xl-0 {
    margin-right: 0 !important;
  }
  .flex-from-xl-column {
    flex-direction: column;
  }
}
@media (max-width:991px) {
  .px-from-lg-3_3 {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
  .py-from-lg-3_3 {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }
  .offset-form-lg-0 {
    margin-left: 0 !important;
  }
  .bg-form-lg-gray {
    background: #edf1f5;
  }
  .pb-from-lg-0 {
    padding-bottom: 0 !important;
  }
  .pb-from-lg-3_2 {
    padding-bottom: 20px !important;
  }
  .pt-from-lg-3_2 {
    padding-top: 40px !important;
  }
  .position-from-lg-initial {
    position: initial !important;
  }
  .pl-from-lg-0 {
    padding-left: 0 !important;
  }
  .tab-content-from-lg-03 {
    width: 940px !important;
  }
  .px-from-lg-3 {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
  .mr-from-lg-2 {
    margin-right: 10px !important;
  }
  .flex-from-md-row {
    flex-direction: row;
  }
  .d-from-lg-none {
    display: none !important;
  }
  .col-from-lg-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .tab-content-from-lg-04 {
    width: 880px !important;
  }

}
@media (max-width: 767px) {
  .mb-from-md-4 {
    margin-bottom: 30px !important;
  }
  .mb-from-md-3 {
    margin-bottom: 15px !important;
  }
  .p-single-use-2 {
    padding: 20px !important;
  }
}
@media (max-width: 575px) {
  .pl-from-sm-0 {
    padding-left: 0 !important;
  }
  .col-from-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .mr-from-sm-0 {
    margin-right: 0 !important;
  }
  .flex-from-sm-column {
    flex-direction: column;
  }
  .tab-content-from-sm-04 {
    width: 860px !important;
  }
  .col-from-sm-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }

}







