//@font-face {
//    font-family: 'Metropolis-Bold';
//    src: url('../fonts/Metropolis-Bold.eot?#iefix') format('embedded-opentype'),url('../fonts/Metropolis-Bold.woff2') format('opentype'),
//    url('../fonts/Metropolis-Bold.woff') format('woff'), url('../fonts/Metropolis-Bold.ttf') format('truetype');
//    font-weight: normal;
//    font-style: normal;
//}
//
//@font-face {
//    font-family: 'Metropolis-Medium';
//    src: url('../fonts/Metropolis-Medium.eot?#iefix') format('embedded-opentype'),url('../fonts/Metropolis-Medium.woff2') format('woff2'),
//    url('../fonts/Metropolis-Medium.woff') format('woff'), url('../fonts/Metropolis-Medium.ttf') format('truetype');
//    font-weight: normal;
//    font-style: normal;
//}
//
//@font-face {
//    font-family: 'Metropolis-SemiBold';
//    src: url('../fonts/Metropolis-SemiBold.eot?#iefix') format('embedded-opentype'),url('../fonts/Metropolis-SemiBold.woff2') format('opentype'),
//    url('../fonts/Metropolis-SemiBold.woff') format('woff'), url('../fonts/Metropolis-SemiBold.ttf') format('truetype');
//    font-weight: normal;
//    font-style: normal;
//}
//
//@font-face {
//    font-family: 'Metropolis-Regular';
//    src: url('../fonts/Metropolis-Regular.eot?#iefix') format('embedded-opentype'),url('../fonts/Metropolis-Regular.woff2') format('opentype'),
//    url('../fonts/Metropolis-Regular.woff') format('woff'), url('../fonts/Metropolis-Regular.ttf') format('truetype');
//    font-weight: normal;
//    font-style: normal;
//}

@font-face {
  font-family: 'DMSans-Bold';
  src: url('../fonts/DMSans-Bold.eot?#iefix') format('embedded-opentype'), url('../fonts/DMSans-Bold.woff') format('woff'), url('../fonts/DMSans-Bold.ttf') format('truetype'), url('../fonts/DMSans-Bold.svg#DMSans-Bold') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'DMSans-Regular';
  src: url('../fonts/DMSans-Regular.eot?#iefix') format('embedded-opentype'), url('../fonts/DMSans-Regular.woff') format('woff'), url('../fonts/DMSans-Regular.ttf') format('truetype'), url('../fonts/DMSans-Regular.svg#DMSans-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'DMSans-Medium';
  src: url('../fonts/DMSans-Medium.eot?#iefix') format('embedded-opentype'), url('../fonts/DMSans-Medium.woff') format('woff'), url('../fonts/DMSans-Medium.ttf') format('truetype'), url('../fonts/DMSans-Medium.svg#DMSans-Medium') format('svg');
  font-weight: normal;
  font-style: normal;
}


