.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  min-height: 100%;
  width: 240px;
  z-index: 999;
  background: #fff;
  padding-top: 21px;
  transition: all .5s ease-in-out;
  box-shadow: 4px 0px 16px rgba(54, 57, 71, 0.06);

  @include respond-to(lg) {
    transform: translateX(-100%);
    box-shadow: none;
  }


  &.open {
    transform: translateX(0);
    position: relative;
  }
}

.content-page {
  padding-left: 240px;
  transition: all .5s ease-in-out;

  @include respond-to(lg) {
    padding-left: 0;
  }
}

.logo-wrapper {
  padding-left: 16px;
  margin-bottom: 38px;

  a {
    display: inline-block;
  }

}

.side-menu__item {
  transition: all .3s;

  &.active {

    .side-menu__sub-item {
      background: #e5f0fd;
      color: #218aef;

      &.main-icon-arrow .side-menu__arrow {
        transform: rotate(0deg);
        path {
          stroke: #218aef;
        }
      }

      .side-menu__icon {
        path {
          fill: #218aef;
        }
      }

    }

    .side-menu__sub-list {
      display: block;
    }
  }
}






.side-menu__sub-item {
  font: 14px/1 'DMSans-Medium', sans-serif;
  color: #a1a1a9;
  padding: 17px 48px 17px 56px;
  background: transparent;
  position: relative;
  display: block;
  border-left: 2px solid transparent;
  transition: all .3s;

  &:hover {
    text-decoration: none;
    color: #218aef;
    background: #e5f0fd;

    .side-menu__arrow {
      path {
        stroke: #218aef;
      }
    }


    .side-menu__icon {
      path {
        fill: #218aef;
      }
    }
  }

  &.main-icon-arrow .side-menu__arrow {
    position: absolute;
    right: 16px;
    top: 16px;
    width: 16px;
    height: 16px;
    background-size: contain;
    transform: rotate(-90deg);
    transition: all .3s;
  }
  //
  //&.main-icon-1:before {
  //  background: url("../images/briefcase.svg") center center no-repeat;
  //}
  //&.main-icon-2:before {
  //  background: url("../images/credit-card.svg") center center no-repeat;
  //}
  //&.main-icon-3:before {
  //  background: url("../images/message-square.svg") center center no-repeat;
  //}
  //&.main-icon-4:before {
  //  background: url("../images/help-circle.svg") center center no-repeat;
  //}
  //&.main-icon-5:before {
  //  background: url("../images/link.svg") center center no-repeat;
  //}
  //&.main-icon-6:before {
  //  background: url("../images/users.svg") center center no-repeat;
  //}


  &.main-icon  .side-menu__icon {
    position: absolute;
    left: 16px;
    top: 12px;
    width: 24px;
    height: 24px;
    background-size: contain;
  }


}

.side-menu__sub-list {
  li {
     a {
       display: block;
       font: 14px/1 'DMSans-Medium', sans-serif;
       padding: 17px 17px 17px 48px;
       background: #f6faff;
       color: #2a2a2e;
       transition: all .3s;

       &:hover {
         text-decoration: none;
           color: #fff;
       }
     }

    &.active,&:hover {

      a {
        color: #2e90f0;
      }

    }
  }
}


.menu-btn:not(.open) {
  opacity: 0;
  visibility: hidden;

  @include respond-to(lg) {
    opacity: 1;
    visibility: visible;
  }
}


.menu-btn {
  position: absolute;
  top: -3px;
  left: calc( 100% + 30px);
  padding: 18px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  cursor: pointer;
  transition: all .5s ease-in-out;
  z-index: 999;
  border-radius: 10px;



  @include respond-to(lg) {
    opacity: 1;
    visibility: visible;
  }

  @include respond-to(sm-tablets) {
    left: calc( 100% + 5px);
    padding: 15px;
  }
}

.menu-btn__burger {
  width: 30px;
  height: 4px;
  background: #8F8F9F;
  border-radius: 5px;
  transition: all .5s ease-in-out;
}

.menu-btn__burger::before,
.menu-btn__burger::after {
  content: '';
  position: absolute;
  width: 30px;
  height: 4px;
  background: #8F8F9F;
  border-radius: 5px;
  transition: all .5s ease-in-out;
}

.menu-btn__burger::before {
  transform: translateY(-10px);
}

.menu-btn__burger::after {
  transform: translateY(10px);
}


.menu-btn.open .menu-btn__burger {
  transform: translateX(-50px);
  background: transparent;
}

.menu-btn.open .menu-btn__burger::before {
  transform: rotate(45deg) translate(35px, -35px);
}

.menu-btn.open .menu-btn__burger::after {
  transform: rotate(-45deg) translate(35px, 35px);
}

.for-overlay.open {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 9999;

  &:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background: rgba(#000,0.7);
    transition: all .5s ease-in-out;
  }

  .scroll {
    overflow: auto;
  }

}

body.menu-open {
  overflow: hidden;
  position: relative;
}

/*----bag-fix------*/

.for-overlay.open,.for-overlay.open .scroll,.for-overlay.open:after {

  height: calc(var(--vh, 1vh) * 100);
  width: 100%;

}

.for-overlay.open .scroll {
  min-height: calc(var(--vh, 1vh) * 100) !important;
}

/*----bag-fix------*/


body.menu-open:after {
  content: " ";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  min-height: 100vh;
  width: 100%;
  z-index: 1;
}

.side-menu__icon {
  path {
    transition: all .3s;
  }
}

.old-sidebar .sidebar {
  background: #0b0730;
}


.old-sidebar .side-menu__sub-item {
  color: #fff;
}


.old-sidebar .side-menu__item.active .side-menu__sub-item {
  background: #251e74;
  border-left: 2px solid #34cca9;

}

.old-sidebar .side-menu__sub-list li a {
  color: #fff;
}

.old-sidebar .side-menu__sub-item:hover {
  text-decoration: none;
  color: #34cca9;
  background: #251e74;
}

.old-sidebar .side-menu__sub-list li.active a,.old-sidebar .side-menu__sub-list li:hover a {
  color: #34cca9;
}


.old-sidebar .side-menu__sub-list li a {
  background: #0f0941;
}

.old-sidebar .side-menu__sub-item.main-icon:before {
  content: "";
  position: absolute;
  left: 16px;
  top: 12px;
  width: 24px;
  height: 24px;
  background-size: contain;
}


.old-sidebar .side-menu__sub-item.main-icon-arrow:after {
  content: "";
  position: absolute;
  right: 16px;
  top: 16px;
  width: 16px;
  height: 16px;
  background: url(../images/arrow-black.svg) center center no-repeat;
  background-size: contain;
  -webkit-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
}


.old-sidebar .side-menu__sub-item.main-icon-1:before {
  background: url(../images/briefcase.svg) center center no-repeat;
}


.old-sidebar .side-menu__sub-item.main-icon-2:before {
  background: url(../images/credit-card.svg) center center no-repeat;
}


.old-sidebar .side-menu__sub-item.main-icon-3:before {
  background: url(../images/message-square.svg) center center no-repeat;
}

.old-sidebar .side-menu__sub-item.main-icon-4:before {
  background: url(../images/help-circle.svg) center center no-repeat;
}

.old-sidebar .side-menu__sub-item.main-icon-5:before {
  background: url(../images/link.svg) center center no-repeat;
}

.old-sidebar .side-menu__sub-item.main-icon-6:before {
  background: url(../images/users.svg) center center no-repeat;
}


.old-sidebar .menu-btn {
  top: -13px;
  padding: 18px !important;
}
