body {
  position: relative;
  min-height: 100vh;
}
.container {

  @include respond-to(lg-screens) {
    max-width: 1070px;
  }

}
.breadcrumb-main {
  padding: 0;
  background: transparent;

  @include respond-to(sm-phones) {
    width: 350px;
    padding-bottom: 20px;
  }

  .breadcrumb-item-main {
    position: relative;
    padding-right: 9px;
    color: #9595a5;

    &:before {
      content: ">";
      position: absolute;
      right: -4px;
      top: 0;
      padding: 0;
    }

    &:last-child:before {
      display: none;
    }

    &, a {
      font: 12px/1 'DMSans-Regular', sans-serif;
      color: #6c757d;

      &:hover {
        text-decoration: none;
        color: #6c757d;
      }

    }

  }
}
.input-search-img {
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
}
.input-search {
  padding: 12px 47px 12px 14px;
  height: 100%;
  line-height: 1;
  outline: none !important;
  box-shadow: none !important;
}
.input-search::placeholder {
  font: 14px/1.5 'DMSans-Medium', sans-serif;
  color: #8F8F9F;
  opacity: 0.7;
}
.input-style::placeholder {
  line-height: 1.5 !important;
}
.btn {
  line-height: 1.3;
  position: relative;
  border-radius: 4px;
  transition: all .3s;
  border: 1px solid transparent;

  &:hover {
    background: #fff;
    color: #218AEF;
    border: 1px solid #218AEF;
    text-decoration: none;
  }

  &_main {
    background: #218AEF;
    font: 14px/1 'DMSans-Medium';
    color: #FFFFFF;
    padding: 15px 20px 15px 32px;

    @include respond-to(lg) {
      padding: 15px 15px 15px 18px;
    }

    @include respond-to(lg-phones) {
      padding: 15px 31px 15px 31px;
    }

    .plus {
      left: 20px;
      top: 14px;

      @include respond-to(lg) {
        left: 5px;
      }

      @include respond-to(lg-phones) {
        left: 13px;
      }

    }
  }
}
.nav-tabs-main {

  border: 0;

  .nav-item-main {
    margin-right: 8px;
    margin-bottom: 0;
  }

  .nav-link-main {
    font: 13px/1 'DMSans-Regular', sans-serif;
    color: #1C1C20;
    padding: 11px 9px 9px 9px;
    min-width: 96px;
    text-align: center;
    background: transparent;
    border-radius: 4px;
    transition: all .3s;
    border: 0;
    margin-bottom: 0;

    &.active, &:hover {
      background: #218AEF;
      color: #fff;
    }

  }

}
.hero-main__col-indent {
  margin-bottom: 6px;

  @include respond-to(lg) {
    margin-bottom: 15px;
  }
}
.hero-main__wrap-btn {
  padding-left: 6px;
  min-width: 138px;
  text-align: left;

  @include xl-screens {
    flex: 0 0 26.733333%;
    max-width: 26.733333%;
    text-align: right;
    padding-left: 0;
  }

  @include respond-to(lg-phones) {
    flex: 0 0 100%;
    max-width: 100%;
    text-align: center;
    padding-left: 15px;
    min-width: auto;
  }

}
.hero-main__row-indent {
  margin-bottom: 16px;
}
.wrap-nav-tabs-main {
  padding: 8px 17px;
  background: #EDEFF6;
  border-radius: 4px;
  max-width: calc(100% - 30px);
  margin: 0 auto 30px;

  @include respond-to(sm) {
    background: transparent;
    padding: 0;
  }
}
.table-row-style {
  background: #FFF;
  border: 1px solid #DDDDE8;
  box-sizing: border-box;
  border-radius: 8px;
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 3px;
  }
}
.cell-style, .product-name {
  display: block;
  padding: 18px 0;

}
.product-name, .product-no, .ticket-name {
  display: block;
  font: 14px/1 'DMSans-Medium', sans-serif;
  color: #1C1C20;
  transition: all .3s;
}
.product-name:hover, .ticket-name:hover {
  color: #218AEF;
  outline: none !important;
  box-shadow: none !important;
}
.cell-style {
  font: 13px/1.3 'DMSans-Medium', sans-serif;
  color: #8F8F9F;
}
.status {
  display: block;
  width: 100%;
  font: 10px/1.8 'DMSans-Medium', sans-serif;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  border-radius: 4px;
  padding: 4px;
  text-align: center;
}
.val-01 {
  color: #34CCA9;
  background: rgba(#34CCA9, 0.1);
}
.val-02 {
  color: #FCA118;
  background: rgba(#FCA118, 0.1);
}
.val-03 {
  color: #218AEF;
  background: rgba(#218AEF, 0.1);
}
.val-04 {
  color: #8F8F9F;
  background: rgba(#8F8F9F, 0.1);
}
.val-05 {
  color: #E93C3C;
  background: rgba(#E93C3C, 0.1);
}
.btn-circle-span {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: transparent;
  border: 0;
}
.btn-circle-span:focus {
  outline: none;
  background: rgba(#8F8F9F, .1);
}
.btn-circle-span span {
  display: block;
  font-size: 26px;
  color: #9AA3B2;
  line-height: 0.25;
  position: relative;
  top: -8px;
}
.showing-table-cell {
  font: 14px/1 'DMSans-Regular', sans-serif;
  color: rgba(#8F8F9F, 0.8);
  margin-left: 3px;
}
.breadcrumb-second {
  background: transparent;
  margin-right: 2px;
}
.breadcrumb-item-second {
  padding: 0 !important;
  margin-right: 6px;

  &:last-child {
    margin-right: 0;
  }

  &:before {
    display: none !important;
  }

  a, &.active {
    font: 15px/1 'DMSans-Regular', sans-serif;
    color: #8F8F9F;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(#DDDDE8, 0.5);
    border-radius: 4px;
    transition: all .3s;

    &:hover {
      text-decoration: none;
    }

  }

  &.active, &:hover a {
    color: #fff;
    background: #218AEF;
  }
}
.input-search-wrapper {
  max-width: 400px;
  padding-right: 0;

  @include respond-to(lg-phones) {
    max-width: 100%;
    flex: 0 0 100%;
    padding-right: 15px;
    margin-bottom: 15px;
  }

}
.wrap-tab-content-xl {
  width: 100%;
  margin-right: 15px;
  overflow: auto;
}
.tab-content-xl {
  width: 991px;
}
.wrap-nav-tabs-md {
  overflow: auto;
  overflow-y: hidden;
  width: 100%;

  @include respond-to(sm) {
    background: #EDEFF6;
    padding: 10px;
  }

}
.nav-tabs-md {
  @include respond-to(sm) {
    width: 658px;
  }
}
.wrap-breadcrumb {

  @include respond-to(sm-phones) {
    overflow: auto;
  }

}
//----------------------------------------------------------------------------------------------------------------------

.serial-numb {
  font: 12px/1 'DMSans-Bold', sans-serif;
  text-transform: uppercase;
  color: #8F8F9F;
}
.content-wrap {
  display: flex;
  align-items: center;
  padding: 0 10px 0 10px;
}
.content-wrap.custom-02 {
  padding: 0;

  @include xl-screens {
    padding: 0 10px 0 10px;
  }
}
.content-wrap.custom-03 {
  padding: 0 10px 0 10px;

  @include xl-screens {
    padding: 0 10px 0 27px;
  }
}
.content-wrap.custom-04 {
  padding: 0 10px 0 7px;
}
.content-wrap.custom-05, .content-wrap.custom-06 {
  flex: 0 0 13%;
  max-width: 13%;

}
.content-wrap.custom-05 {
  padding: 0 10px 0 17px;
}
.content-wrap.custom-06 {
  padding: 0 10px 0 19px;
}
.content-wrap.custom-07 {
  flex: 0 0 11%;
  max-width: 11%;
  padding: 0 10px 0 10px;
}
.submitted {
  display: inline-block;
}
.submitted:first-child {
  position: relative;
  padding-right: 10px;
  margin-right: 9px;
}
.submitted:first-child:after {
  content: "";
  position: absolute;
  right: 0;
  top: -4px;
  bottom: 0;
  margin: auto;
  width: 1px;
  height: 13px;
  background: #8F8F9F;
}
.content-wrap.custom-08 {
  flex: 0 0 4%;
  max-width: 4%;
  padding: 0;

  .dropdown {
    left: 7px;
  }
}
.content-wrap.custom-01 {
  padding-left: 14px;
}
.breadcrumb-col {
  margin-bottom: 19px;
}
.content-wrap-01 {
  margin-bottom: 28px;
}
.content-wrap-02 {
  text-align: center;
  margin-bottom: 17px;

  p {
    font: 13px/1.1 'DMSans-Medium', sans-serif;
    color: #E93C3C;
    padding: 12px 0;
    margin-bottom: 0;
    background: #FDECEC;
    border-radius: 4px;
  }
}
.content-wrap-03 {
  margin-bottom: 30px;
  padding-bottom: 18px;
  border-bottom: 1px solid #DDDDE8;

  h2 {
    font: 18px/1 'DMSans-Medium', sans-serif;
    color: #1C1C20;
    margin-bottom: 0;
  }

  .status {
    max-width: 96px;
  }

}
.ticket-card {
  padding: 18px 25px;

  @include respond-to(lg) {
    padding: 20px 10px;
  }

  @include respond-to(lg-phones) {
    text-align: center;
  }
}
.contact-card {
  padding: 22px 22px;

  @include respond-to(lg-phones) {
    padding: 18px 18px;
  }

  .content-wrap-03 {
    margin-bottom: 24px;
  }

}
.ticket-card, .contact-card, .ip-card {
  background: #fff;
  border: 1px solid #DDDDE8;
  border-radius: 8px;
}
.content-wrap-04 {
  flex: 0 0 48%;
  max-width: 48%;

  @include respond-to(sm) {
    flex: 0 0 50%;
    max-width: 50%;
  }
  @include respond-to(lg-phones) {
    flex: 0 0 100%;
    max-width: 100%;
    padding: 0;
  }
}
.content-wrap-04, .content-wrap-05, .content-wrap-06 {
  margin-bottom: 31px;

  @include respond-to(lg-phones) {
    margin-bottom: 30px;
  }
  h6 {
    font: 12px/1.3 DMSans-Bold, sans-serif;
    color: #8F8F9F;
    text-transform: uppercase;
    margin-bottom: 5px;
  }
  p {
    font: 16px/1.3 DMSans-Medium, sans-serif;
    color: #1C1C20;
  }
}
.wrap-p {
  p:only-child, p:last-child {
    margin-bottom: 0;
  }
  p {
    margin-bottom: 7px;
  }
}
.input-style, textarea {
  display: block;
  width: 100%;
  font: 14px/1.4 DMSans-Medium, sans-serif;
  color: #1C1C20;
  padding: 16px 16px;
  background: #FFFFFF;
  border: 1px solid #DDDDE8;
  border-radius: 4px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: none !important;
}
.input-style::placeholder, .area-style::placeholder {
  font: 14px/1.4 DMSans-Regular, sans-serif;
  color: rgba(#8F8F9F, 0.8);
}
.input-wrap {
  margin-bottom: 8px;
}
.area-style {
  font: 14px/1.4 DMSans-Regular, sans-serif;
  display: block;
  width: 100%;
  color: #1C1C20;
  padding: 19px 16px;
  background: #FFFFFF;
  border: 1px solid #DDDDE8;
  border-radius: 4px;
  height: 167px;
  resize: none;

  @include respond-to(md) {
    padding: 15px;
  }
}
.label-style {
  display: block;
  width: 100%;
  position: relative;
  border-radius: 4px;
  padding: 16px 12px;
  text-align: center;
  cursor: pointer;
  overflow: auto;
  margin-bottom: 13px;

  &:focus {
    outline: none;
  }
}
.file-style {
  position: absolute;
  z-index: -1;
  left: 0;
  top: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
}
.labelFile {
  padding: 11px 12px;
  border: 1px dashed #218AEF;

  span {
    font: 14px/1.5 DMSans-Medium, sans-serif;
    position: relative;
    color: #218AEF;
    padding-left: 28px;

    &:before {
      content: "";
      position: absolute;
      left: -3px;
      top: -3px;
      width: 24px;
      height: 24px;
      background: url("../images/attach.svg") center center no-repeat;
      background-size: contain;
    }
  }
}
.btn-wrap .btn_main {
  display: block;
  border-radius: 4px;
  padding: 20px 20px 20px 20px;
  text-align: center;
  max-width: 300px;
  margin: auto;

}
.input-wrap {
  p {
    font: 12px/1.3 DMSans-Medium, sans-serif;
    color: #8F8F9F;
    text-align: center;
    padding: 0 10px;
  }
}
.breadcrumb-sm-02 {
  @include respond-to(lg-phones) {
    width: 445px;
    padding-bottom: 0;
  }
}
.wrap-breadcrumb-sm-02 {
  @include respond-to(lg-phones) {
    overflow: auto;
    padding-bottom: 10px;
  }
}
.ip-item {
  h6 {
    font: 12px/1.3 DMSans-Medium, sans-serif;
    color: #8F8F9F;
    margin-bottom: 10px;
  }
  p {
    font: 14px/1.3 DMSans-Medium, sans-serif;
    color: #1C1C20;
  }
}
.content-wrap-06 {
  flex: 0 0 52%;
  max-width: 52%;

  @include respond-to(sm) {
    flex: 0 0 50%;
    max-width: 50%;
  }
  @include respond-to(lg-phones) {
    flex: 0 0 100%;
    max-width: 100%;
    padding: 0;
  }
}
.ip-col {
  padding-left: 18px;
}
.ip-box {
  border-bottom: 1px solid #DDDDE8;
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }
}
.ip-card {
  padding: 21px;

  @include respond-to(lg) {
    padding: 20px 10px;
  }

  .content-wrap-03 {
    padding-bottom: 22px;
    margin-bottom: 21px;
  }

}
.ip-item .dropdown {
  left: -5px;
  top: 3px;

  .dropdown-menu-style {
    left: -50px !important;
  }
}
.wrap-ip-box {
  margin-bottom: 32px;

}
.btn-wrap-02 {
  margin-bottom: 10px;

  & + .btn-wrap {
    .btn {
      padding: 15px;
    }
  }

  .btn {
    display: block;
    padding: 15px;
    max-width: 300px;
    margin: auto;
    font: 14px/1.3 DMSans-Medium;
    background: #F3F4F8;
    color: #8F8F9F;

    &:hover {
      background: #fff;
      color: #8F8F9F;
      border: 1px solid #8F8F9F;
    }
  }

}
.tab-content-02 {
  width: 1040px;
}
.main-pb {
  padding-bottom: 100px;

  @include respond-to(md) {
    padding-bottom: 50px;
  }
}
//------------------------------

.box {
  min-height: 249px;
  box-shadow: 0 4px 8px rgba(28, 28, 32, .04);
  border-radius: 8px;
  padding: 16px 17px 50px 19px;
  max-width: 336px;

  @include respond-to(laptops-md) {
    padding: 16px 15px 50px 15px;
    margin: auto;
  }
}
.box:before {
  content: "";
  position: absolute;
  top: 0;
  width: 100%;
  height: 4px;
  left: 0;
}
.box-wrap {
  padding: 0;
  margin-bottom: 1.3rem;

  @include respond-to(laptops-md) {
    padding: 0 15px;
  }
}
.box-row {
  @include respond-to(laptops-md) {
    padding: 0;
  }
}
.box-wrap:nth-child(3n+2) .box {
  margin: auto;

  @include respond-to(laptops-md) {
    margin: auto;
  }
}
.box-wrap:nth-child(3n+3) .box {
  margin: 0 0 0 auto;

  @include respond-to(laptops-md) {
    margin: auto;
  }
}
.box.purple-before:before {
  background: #8D21EF;
}
.box.blue-before:before {
  background: #218AEF;
}
.box.green-before:before {
  background: #34CCA9;
}
.box.red-before:before {
  background: #CF2F2F;
}
.box.violet-before:before {
  background: #218AEF;
}
.box__span {
  font: 14px/1.2 'DMSans-Regular';
}
.box p {
  font: 13px/1.4 DMSans-Regular;
}
.box .link-span {
  position: absolute;
  bottom: 19px;
  left: 19px;
  font: 14px/1.2 'DMSans-Regular';
  text-transform: capitalize;
  color: #8D21EF;

  @include respond-to(md) {
    left: 0;
    width: 100%;
  }
}
.box__img-2 {
  margin-right: 0.15rem;
}
.box header {
  @include respond-to(md) {
    flex-direction: column;
  }

}
//------------------------------

// Create-Account.html
// ---------------------------------------------------

.register-section:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 50%;
  height: 100%;
  background: url(../images/create-account-1.png) center center/cover no-repeat;
  z-index: 5;

  @include respond-to(md) {
    content: none;
  }
}
.register-section:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 50%;
  height: 100%;
  background: linear-gradient(180deg, #3D2AA4 0%, #291A7A 100%);
  z-index: 4;

  @include respond-to(md) {
    content: none;
  }
}
.register-section {
  background: #fff;
}
.register-section__logo-wrap {
  position: absolute;
  top: 23px;
  left: 33px;
  z-index: 6;

  @include respond-to(md) {
    left: 50%;
    transform: translateX(-50%);
  }

}
.register-section__logo-wrap svg {
  width: 214px;
  height: 55px;

}
.logo-text {
  @include respond-to(md) {
    path:first-child, path:last-child {
      fill: rgb(28, 28, 32);
    }
    path:last-child {
      stroke-width: 0.4;
      stroke: #1C1C20;
    }
  }
}
a.register-section__link, a.register-section__link:hover {
  text-decoration: underline;
  color: #218AEF;
}
.register-section__subtitle {
  color: rgba(143, 143, 159, 0.8);
}
.register-input input {
  color: #1C1C20;
}
.social-link-btn {
  display: inline-flex;
  border-radius: 4px;
  padding: 10px 25px 10px 19px;
  align-items: center;
  font: 15px/1.3 'DMSans-Regular';
  min-width: 144px;
  text-decoration: none !important;
  justify-content: center;
}
.social-link-btn svg {
  margin-right: 19px;
}
.register-section__social-links .social-link-btn:last-of-type {
  margin-right: 0 !important;
}
.on-line-text {
  font-size: 12px;
  top: 0;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  background: #fff;
  padding: 20px;
}
.register-input input {
  font: 14px/1.2 'DMSans-Regular';
  background: #F8F8F9;
  border: 1px solid #F4F4F8;
  border-radius: 4px;
  padding: 14px;
}
.register-wrap-for-2 .register-form-element:first-child {
  padding: 0 8px 0 0;

  @include respond-to(sm-tablets) {
    padding: 0;
  }
}
.register-wrap-for-2 .register-form-element:last-child {
  padding: 0 0 0 8px;

  @include respond-to(lg) {
    padding: 0;
    margin-top: 10px;
  }

  @include respond-to(md) {
    padding: 0 0 0 8px;
    margin-top: 0;
  }

  @include respond-to(sm-tablets) {
    padding: 0;
    margin-top: 10px;
  }

}
.select-hidden {
  display: none;
  visibility: hidden;
  padding-right: 10px;
}
.select {
  cursor: pointer;
  display: block;
  position: relative;
  min-height: 47px;
}
.register-select .select-options li, .select-styled {
  font-size: 13px;
  overflow: auto;
}
.select-options {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 999;
  margin: 0;
  padding: 0;
  list-style: none;
  border: 2px solid #785adc;
  border-radius: 8px;
  overflow: hidden;
}
.select-options li {
  margin: 0;
  padding: 12px 8px;
  color: #785adc;
  background: #ffffff;

  //&:first-child {
  //  border-top-left-radius: 8px;
  //  border-top-right-radius: 8px;
  //}
  //&:last-child {
  //  border-bottom-left-radius: 8px;
  //  border-bottom-right-radius: 8px;
  //}
}
.select-options li:before {
  display: none;
}
.select-options li:hover, .select-options li.active {
  color: #ffffff;
  background: #785adc;

}
.select-options li[rel="hide"] {
  display: none;
}
.select-styled::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 11px;
  width: 0;
  height: 0;
  border-top: 0.45em solid;
  border-right: 0.4em solid transparent;
  border-bottom: 0;
  border-left: 0.4em solid transparent;
  transform: translateY(-50%);
}
.register-select {
  background: #F8F8F9;
  border: 1px solid #F4F4F8;
  border-radius: 4px;
  height: 47px;
}
.register-select .select-styled {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  left: 0;
  font: 14px/1.3 'DMSans-Regular';
  color: #1C1C20;
  padding: 14px 28px 14px 39px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.img-country {
  width: 20px;
  height: 20px;
  object-fit: contain;
  position: absolute;
  left: 9px;
  top: 50%;
  transform: translateY(-50%);
}
.register-select_img .select-options li {
  position: relative;
  padding: 12px 20px 12px 40px;
}
.register-select_mini {
  max-width: 64px;
}
.register-select_mini .select-styled {
  padding: 15px 15px 15px 13px;
  font-size: 14px;
}
.register-select_mini .select-styled::after {
  right: 6px;
  border-top: 5px solid;
  border-right: 3px solid transparent;
  border-bottom: 0;
  border-left: 3px solid transparent;
}
.after-left-line:after {
  content: "";
  position: absolute;
  top: 25%;
  right: 0;
  width: 1px;
  height: 50%;
  background: #B3B3B9;
}
.register-section__tel-block {
  border-radius: 4px;
  border: 1px solid #F4F4F8;
  background: #F8F8F9;
}
.register-section__tel-block .register-select_mini, .register-section__tel-block .input-style {
  border-radius: 0;
  border: none;
}
.rounded-main.rounded label:after {
  background: #218AEF;
}
.rounded-main.rounded input[type=radio]:checked + label:before {
  border: 1px solid #218AEF;
}
.rounded-main.rounded label {
  background: transparent;
  padding: 12px 6px 11px 45px;
}
.rounded-main.rounded label:before {
  width: 20px;
  height: 20px;
}
.rounded-main.rounded label:after {
  width: 10px;
  height: 10px;
  left: 20px;
  top: 18px;
}
.rounded-main.rounded .check-content-first__title {
  top: 3px;
}
.register-section__rounded {
  background: rgba(33, 138, 239, 0.08);
  border: 1px solid rgba(33, 138, 239, 0.12);
  border-radius: 4px;
}
.btn_blue {
  font: 14px/1.7 'DMSans-Medium';
  background: #218AEF;
  color: #fff;
  width: 100%;
  padding: 11px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
}
.checkbox-custom input[type="checkbox"] {
  position: absolute;
  z-index: -1;
}
.checkbox-custom label {
  padding-left: 25px;
  z-index: 10;
}
.checkbox-custom label:after {
  content: "";
  position: absolute;
  left: 0;
  top: -2px;
  bottom: 0;
  margin: auto;
  width: 20px;
  height: 20px;
  background: #F8F8F9;
  border: 1px solid #F4F4F8;
  border-radius: 4px;
}
.checkbox-custom label:before {
  content: "";
  position: absolute;
  left: 4px;
  top: -1px;
  bottom: 0;
  margin: auto;
  width: 12px;
  height: 9px;
  background: url(../images/check.svg) center center/contain no-repeat;
  opacity: 0;
  z-index: 10;
}
.checkbox-custom input:checked + label:after {
  background: #218AEF;
  border: 1px solid #218AEF;
}
.checkbox-custom input:checked + label:before {
  opacity: 1;
}
.social-link-btn_circle {
  border-radius: 50%;
  min-width: 48px;
  height: 48px;
  padding: 0;
}
.social-link-btn_circle svg {
  margin-right: 0;
}
a.register-section__link-secondary, a.register-section__link-secondary:hover {
  text-decoration: none;
  color: #8F8F9F;
}
.card-main__heading h5 {
  font-size: 18px;
  line-height: 1.2;
}
.card-main:hover .card-main__link, .link-mini:hover {
  text-decoration-line: none;
}
.card-main__link, .link-mini {
  font: 12px/1.3 'DMSans-Medium';
  text-decoration-line: underline;
}
.card-main__img {
  object-fit: contain;
  height: 32px;
  width: 32px;
}
.card-main {
  box-shadow: 0 4px 8px rgba(28, 28, 32, 0.04);
  border-radius: 8px;
  padding: 16px 16px 12px 16px;
}
.wrap-card-main {

  @include respond-to(md) {
    margin-bottom: 15px;
  }

}
.table-main__title {
  font: 12px/1.3 'DMSans-Medium';
  text-transform: capitalize;
}
.table-main {
  box-shadow: 0 4px 8px rgba(28, 28, 32, 0.04);
  border-radius: 8px;
}
.status-secondary {
  max-width: 96px;
  border-radius: 20px;
}
.table-main__border-bottom-gray {
  border-bottom: 1px solid #DDDDE8;
}
.additional-icon-links a:first-child {
  margin-right: 10px;
}
.payment-info {
  box-shadow: 0 4px 8px rgba(28, 28, 32, 0.04);
  border-radius: 8px;
  padding: 34px 39px 40px 39px;

  @include respond-to(md) {
    padding: 0;
  }
}
.payment-info__heading p {
  font: 14px/1.7 'DMSans-Regular';
  color: #A1A1A9;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
.rounded-img {
  max-width: 152px;
  height: 56px;
}
.rounded-img label:after, .rounded-img label:before, .rounded-text label:after, .rounded-text label:before {
  content: none !important;
}
.rounded-img label {
  padding: 16px 27px !important;
  border: 1px solid #DDDDE8;
  border-radius: 4px;

  @include respond-to(sm-tablets) {
    padding: 18px 16px 13px !important;
  }

}
.rounded-text label {
  padding: 12px !important;
  background: rgba(#A1A1A9, 0.9);
  border-radius: 4px;
  height: 48px;
}
.rounded-text {
  max-width: 80px;
}
.rounded-img input[type=radio]:checked + label {
  border: 1px solid #218AEF;
}
.rounded-text input[type=radio]:checked + label {
  border: 1px solid #218AEF;
  color: #218AEF;
}
.rounded-img-wrap .rounded-img:not(:last-of-type) {
  margin-right: 15px;
}
.border-left-gray {
  border-left: 1px solid #DDDDE8;
}
.payment-info__list-item {
  background: #f6fafe;
  border-radius: 4px;
  padding: 12px 16px 10px;
  margin-bottom: 6px;
}
.payment-info__list-item p, .payment-info__list-item:last-child {
  margin-bottom: 0;
}
.payment-info__totals {
  bottom: 5px;
  left: 0;
}
.information-bar {
  border-radius: 4px;
  padding: 0 10px;

}
.placeholder-gray::placeholder {
  color: $clr6;
}
.rounded-text:last-child, .social-bar__list li:last-child {
  margin-right: 0 !important;
}
.after-bg-light-gray:after {
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  background: #EDF1F5;
  flex: 0 0 44.7%;
  max-width: 44.7%;
  width: 100%;
  height: 100%;
  z-index: 0;

  @include respond-to(md) {
    display: none;
  }

}
.welcome-screen__list li {
  position: relative;
  padding-left: 41px;
  margin-bottom: 29px;
  font: 14px/1.5 DMSans-Regular;

  @include respond-to(lg) {
    margin-bottom: 15px;
  }
}
.welcome-screen__list li:last-child {
  margin-bottom: 0;
}
.welcome-screen__list li:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  background: url(../images/checkmark.svg) center center/contain no-repeat;
  width: 24px;
  height: 24px;
}
.btn-middle {
  font: 14px/1.3 DMSans-Regular;
  text-transform: uppercase;
  background: #218AEF;
  border-radius: 4px;
  padding: 16px 31px;
  color: #fff;
}
.welcome-screen:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #fff url(../images/interaction.svg) 99% 71%/740px no-repeat;
  z-index: 0;

  @include respond-to(lg) {
    background-position: 135% 36%;
  }
  @include respond-to(md) {
    opacity: 0.2;

  }
  @include respond-to(sm) {
    background-position: 65% 50%;

  }
}
//.referral-link__input-style {
//
//  @include respond-to(lg-screens) {
//    max-width: 268px;
//  }
//}
.social-bar__list li {
  height: 48px;
  width: 48px;
}
.after-table-title-bg {
  position: relative;
}
.after-table-title-bg:after {
  content: "";
  position: absolute;
  right: 0;
  width: 15px;
  height: 47px;
  background: rgba(243, 244, 248, .5);
  top: 0;
}
.select-styled, .select-options li, .card-main, .hover-link-2, .cell-style, .hover-link-2 path, .social-link-btn, .link-hover-v1, .rounded, .link-hover-v2 path, .hover-link-v3, .btn-circle-span, .rounded-img label, .rounded span, .box, .label-style {
  -webkit-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
}
.card-main:hover, .box:hover {
  box-shadow: 0 6px 16px rgba(28, 28, 32, .06);
  transform: translateY(-5px);
}
.hover-link-2:hover {
  border-bottom: 1px solid #218aef;
}
.hover-link-2:hover .cell-style {
  color: #218aef !important;
}
.hover-link-2:hover path, .link-hover-v2:hover path {
  stroke: #218aef !important;
}
.table-main__border-bottom-gray:last-child, .hover-link-2:hover:only-child, .hover-link-2:hover:last-child {
  border: none !important;
}
.social-link-hover-v1:hover {
  margin-right: 0 !important;
  border: 1px solid #cf2d48 !important;
  color: #cf2d48 !important;
}
.social-link-hover-v2:hover {
  background: #1488d0 !important;
}
.social-link-hover-v3:hover {
  background: #2c467d !important;
}
.link-hover-v1:hover {
  text-decoration: none !important;
}
.rounded:hover, .rounded:hover span, .link-hover-v2:hover {
  color: #218aef !important;
}
.rounded-img:hover label {
  border-color: #218aef !important;
}
.hover-link-v3:hover {
  opacity: 0.6;
}
.hover-link-v4:hover {
  text-decoration: underline !important;
}
.btn-circle-span:hover {
  background: rgba(143, 143, 159, .1);
}
//-------------------------------------------//

.form-template__block {
  padding: 33px 40px 0 40px;
  background: #FFFFFF;
  box-shadow: 0 4px 8px rgba(28, 28, 32, 0.04);
  border-radius: 8px;

  @include respond-to(md) {
    padding-left: 20px;
    padding-right: 20px;
  }
  @include respond-to(sm) {
    padding-left: 15px;
    padding-right: 15px;

  }
}
.form-template__block .register-wrap-for-2 .register-form-element:last-child {
  @include respond-to(lg) {
    margin-top: 0;
  }
  @include respond-to(sm-tablets) {
    margin-top: 10px;

  }

}
.form-template__block .register-select, .form-template__block .register-input input, .form-template__block .register-section__tel-block {
  background: transparent;
}
.form-template__block .register-select_w-middle {
  margin-right: 8px;
  max-width: 345px;
  width: 100%;

  @include respond-to(sm) {
    max-width: 50%;
  }

  @include respond-to(sm-tablets) {
    max-width: 100%;
    margin: 0 0 10px 0;
  }
}
.form-template__block h2.dm-sans-bold {
  font-size: 16px;
}
.form-template__block .register-select_not-img .select-styled {
  padding-left: 14px;
}
.form-template__block .btn-middle {
  padding: 12px 48px;

  @include respond-to(sm-tablets) {
    width: 100%;
    padding: 16px 15px;
  }
}
.form-template__block .register-section__rounded {
  background: transparent;
  border: none;
}
.form-template__block .rounded-main.rounded .check-content-first__title {
  font-size: 15px;
  line-height: 1.4;
}
.form-template__block .rounded-main.rounded label {
  margin-bottom: 0;
  padding: 5px 6px 5px 45px;
}
.links-for-select .register-section__link {
  color: #A1A1A9;
  text-decoration-line: underline;
  margin-right: 13px;
}
.links-for-select .register-section__link.active {
  color: #218AEF;
  text-decoration-line: none;
}
.form-template__block .checkbox-custom-wrap {
  margin-bottom: 10px;
}
.form-template__block .br {
  width: 100%;

  @include respond-to(sm) {
    display: none;
  }
}
.form-template__block .btn-xxl {
  padding: 14px 87px;
  margin-top: 8px;

  @include respond-to(sm-tablets) {
    width: 100%;
    padding: 16px 15px;
  }
}
.form-template__block .rounded-main.rounded label:before {
  left: 0;
  transform: translateY(-40%);
  top: 50%;
}
.form-template__block .rounded-main.rounded label {
  padding: 5px 6px 5px 28px;
}
.form-template__block .rounded-main.rounded label:after {
  left: 5px;
  transform: translateY(-28%);
  top: 50%;
}
.form-template--middle .form-template__block {
  padding-top: 4px;
}
.label-style.labelFile span {
  color: #218AEF;
}
.form-template--middle .labelFile {
  line-height: 1.5;
  margin-bottom: 11px;
  background: 0 0;
  text-align: left;
  padding: 7px 12px 10px 3px;
  border: none;
}
.form-template--middle .area-style {
  height: 94px;
}
.form-template__block .register-wrap-for-2.mb-4_3:last-child {
  margin-bottom: 0 !important;
}
.form-template__block .links-for-select .h6 {

  @include respond-to(sm-tablets) {
    width: 100%;
  }

}
.form-template--middle .form-template__block .register-wrap-for-1 {
  padding: 22px 0 0;
}
.form-template--middle .register-wrap-for-2 .register-form-element {

  @include respond-to(md) {
    padding: 0;

    &:last-child {
      margin-top: 10px;
    }
  }
}
.switch-header {
  margin-bottom: 13px;
}
.switch-form > .register-form-element {
  padding-top: 0;
}
//new-font-fix

.register-section__subtitle {
  line-height: 1.3;
}
.register-label-style label, .label {
  font: 14px/1.3 'DMSans-Regular';
  color: #1C1C20;
  cursor: pointer;
  margin-bottom: 8px;
}
.register-section__content .register-section__link {
  font: 14px/1.3 'DMSans-Regular';
}
.register-section__subtitle .register-section__link {
  font-size: 16px;

  @include respond-to(xs-phones) {
    display: block;
  }

}
.information-bar a {
  @include respond-to(xs-phones-max) {
    display: block;
  }
}
.links-for-select .register-section__link {
  @include respond-to(xs-phones) {
    margin-top: 7px;
  }
}
.checkbox-custom label {
  line-height: 1.45;
}
.links-for-select .h6 {
  margin-bottom: 5px;
}
.dropdown-menu.dropdown-menu-style.dropdown-menu-style--additional {
  padding-top: 20px;
}
.referral-link__input-style .input-style {
  padding: 13px 16px;
}
.hero-main--affilates {
  padding-bottom: 38px;
}
.table-referral .table-referral__title {
  margin-bottom: 13px;
}
.payment-info__title {
  line-height: 1.5;
}
.register-section-sing-up .register-section__header {
  margin-bottom: 27px;
}
.register-section__line {
  margin-bottom: 40px;
}
.register-section-sing-up .register-wrap-for-2 {
  margin-bottom: 21px;
}
.input-search-col .btn_main {
  margin-left: 5px;
}
.modal-body-first__form .rounded label:before {
  top: 17px;
}
.modal-body-first__form .rounded label:after {
  top: 24px;
}
.contact-card .input-style {
  padding: 14px 16px;
}
.contact-card .input-wrap {
  margin-bottom: 7px;
}
.register-section--login .register-label-style {
  margin-bottom: 20px;
}
.form-template--contact .form-template__block {
  padding-top: 27px;
}
.form-template .form {
  padding-bottom: 37px;

  @include respond-to(sm) {

    padding-bottom: 20px;

  }
}
.form-template__header {
  margin-bottom: 30px;

  @include respond-to(sm) {

    margin-bottom: 20px;

  }
}
.form-template__block .register-wrap-for-1 {
  padding: 27px 0 0;

  @include respond-to(sm) {

    padding: 20px 0 0;

  }
}
.form-template__block .register-wrap-for-2 {
  padding: 23px 0 0;

}
.form-template--contact .form-template__block .register-section__link {
  margin-top: 17px;
  display: inline-block;
}
.payment-info__right-part {
  max-width: 212px;

  @include respond-to(sm-tablets) {
    max-width: 100%;
  }

}
.payment-info__link-wrap .link {
  display: block;
}
.payment-info__link-wrap .link:first-child {
  margin-right: 22px;
}
.payment-info__title {
  font: 14px/1.7 'DMSans-Medium';
}
.hero-main--checkout .payment-info__list-item {
  padding: 19px 16px 17px;
  margin-bottom: 9px;
  background: #F7F8FA;

  @include respond-to(sm-tablets) {
    flex-direction: column;
  }
}
.wrap-money-statistic__hr {
  margin-bottom: 22px;
}
.payment-info__link-wrap .link, .payment-info__right-part .payment-info__price {
  line-height: 1.7;
}
.payment-info__promocode-wrap .input-style {
  background: #fff;
  padding-right: 92px;
}
.payment-info__input-wrap .valid-marker {
  opacity: 0;
  visibility: hidden;
  transition: all .3s;
  right: 19px;
  top: 50%;
  transform: translateY(-50%);
  font: 13px/1.3 'DMSans-Medium';
}
.payment-info__input-wrap.input-valid .valid-marker {
  opacity: 1;
  visibility: visible;
}
.payment-info__list-item.hide {
  display: none !important;
}
.valid-check {
  position: relative;
  top: -1px;
}
.hero-main--checkout .p-single-use-2 {
  padding-bottom: 26px !important;
}
.border-bottom-gray {
  border-bottom: 1px solid #DDDDE8;
}
.table-indent {
  padding: 0 32px;
  @include respond-to(sm) {
    padding: 0 15px;
  }

}
.hero-main--confirm .table-main__title {
  text-transform: uppercase;
}
.hero-main__money-col {
  @include respond-to(lg-screens) {
    padding-left: 35px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 14.1%;
    -ms-flex: 0 0 14.1%;
    flex: 0 0 14.1%;
    max-width: 14.1%;
  }

}
.hero-main__amount-col .input-style {
  padding: 10px 10px;
  max-width: 56px;

}
.hero-main__amount-col {
  @include respond-to(lg-screens) {
    padding-left: 37px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 14.1%;
    -ms-flex: 0 0 14.1%;
    flex: 0 0 14.1%;
    max-width: 14.1%;
  }
}
.hero-main__item-col {

  @include respond-to(lg-screens) {
    padding-left: 23px;
  }
}
.hero-main__total-sum, .hero-main__total-per {
  display: inline-block;
  font: 14px/1.28 'DMSans-Medium';
  text-transform: uppercase;

  @include respond-to(sm-phones) {
    width: 100%;
    margin: 0 0 10px 0;
  }

}
.hero-main__total-price {
  padding: 23px 27px 24px;

  @include respond-to(sm-tablets) {
    padding: 23px 0 23px;
  }
}
.form-template--confirm .form {
  padding-top: 36px;
}
.form-template--confirm .form-template__block {
  padding: 0;
  margin-bottom: 36px;
}
.form-template__col-l {
  padding: 0 43px 0 29px;

  @include respond-to(md) {
    padding: 0 15px 0 15px;
  }
}
.form-template--confirm .register-wrap-for-2 {
  padding: 20px 0 0;
}
.form-template--confirm .register-wrap-for-1 {
  padding: 18px 0 0;
}
.question-circle {
  position: absolute;
  right: 14px;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 20px;
  height: 20px;
  z-index: 10;

}
.tooltip-inner {
  background: #218aef;
  color: #fff;
}
.tooltip .arrow::before {
  border-top-color: #218aef;
}
.form-template--confirm .register-input input, .form-template--confirm .register-select {
  border: 1px solid #DDDDE8;
}
.form-template--confirm .checkbox-custom label {
  padding-left: 29px;
}
.form-template__col-r {

  @include respond-to(md-screens) {
    padding-right: 34px;
  }
}
.select-middles-wrap .register-form-element {
  flex: 0 0 96px;
  max-width: 96px;
  width: 100%;
}
.form-template__fake-label {
  font: 14px/1.28 'DMSans-Medium';
  color: rgba(28, 28, 32, 0.5);
}
.register-wrap-for-fake {
  padding: 26px 0 0;
}
.form-template__total-sum {
  font: 16px/1.3 'DMSans-Bold';
  color: #218AEF;
}
.form-template--confirm {
  margin-bottom: 55px;

  @include respond-to(sm) {
    margin-bottom: 38px;
  }
  @include respond-to(sm-phones) {
    margin-bottom: 28px;
  }

}
.header--secondary .logo-wrapper {
  margin-bottom: 0;

  @include respond-to(lg) {
    margin: 0 auto 15px;
  }
}
.header--secondary {
  background: #FFFFFF;
  box-shadow: 0px 4px 12px rgba(28, 28, 32, 0.06);
  padding: 16px 0;
  margin-bottom: 40px;
}
.progress-list__item-progress {
  border-radius: 2px;
  max-width: 237px;
  width: 100%;
  font: 13px/1.3 'DMSans-Regular';
  color: #1C1C20;
  padding: 9px 23px;
  text-align: center;
  background: #E0F0FF;
  margin: 0 !important;

  @include respond-to(md) {
    max-width: 100%;
  }

}
.progress-list__item-progress:after {
  content: '';
  position: absolute;
  left: 94%;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 25.9px;
  height: 25.9px;
  border-top: 3px solid #fff;
  border-right: 3px solid #fff;
  border-radius: 2px;
  background: #e0f0ff;
  z-index: 2;
  transform: rotate(45deg) skew(23deg, 23deg);

  @include respond-to(md) {
    display: none;
  }
}
.tab-content-width {
  @include respond-to(md) {
    width: 686px;

  }

  &--middle {
    @include respond-to(md) {
      width: 900px;

    }
  }

}
.progress-list__item-progress:last-child:after {
  display: none;
}
.progress-list__item-progress.active, .progress-list__item-progress.active:after {
  background: #218AEF;
  color: #fff;
}
.progress-list__item-progress.finish, .progress-list__item-progress.finish:after {
  background: #EFEFEF;
  color: #1C1C20;
}
.form-template--confirm .register-wrap-for-2 .register-form-element {
  @include respond-to(md) {
    padding: 0;
  }
}
.form-template--confirm .register-wrap-for-2 .register-form-element:last-child {
  @include respond-to(md) {
    margin-top: 15px;
  }
}
.form-template__col-l h2 {
  @include respond-to(sm-phones) {
    text-align: center;
    width: 100%;
    padding-left: .5rem;
  }
}
.footer {
  background: #120D34;
  padding: 50px 0 39px;

  @include respond-to(sm) {
    padding: 40px 0 40px;
  }
}
.footer__text {
  font: 13px/1.53 'DMSans-Regular';
  color: #A09EAE;
}
.footer__title {
  font: 13px/1.53 'DMSans-Bold';
  color: #FFFFFF;
  margin-bottom: 23px;
}
.footer__col-contact {
  @include respond-to(lg-screens) {
    padding-left: 25px;
  }

}
.footer__contact-l {
  max-width: 36px;
  margin-right: 10px;

  @include respond-to(sm) {
    margin: 0 0 6px 0;
  }
}
.footer__contact-r .footer__text {
  margin-bottom: 0;
}
.footer__col-info {
  @include respond-to(lg-screens) {
    padding-left: 20px;
  }
}
.footer__contact-r a {
  color: #fff;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}
.footer__top:after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  width: calc(100% - 30px);
  height: 1px;
  margin: auto;
  bottom: 0;
  background: #2B2459;
}
.footer__nav li {
  margin-right: 33px;

  &:last-child {
    margin: 0;
  }

  @include respond-to(sm) {
    margin: 0 0 8px 0;
  }

}
.footer__nav {
  @include respond-to(sm) {
    flex-direction: column;
  }
}
.footer__nav a {
  font: 13px/1.53 'DMSans-Regular';
  color: #A09EAE;
  transition: all .3s;
  text-decoration: none;

  &:hover {
    color: #fff;
  }
}
.form-template--parser .register-label-style label {
  font-size: 12px;
  text-transform: uppercase;
}
.form-template--parser .register-wrap-for-2 {
  padding: 13px 0 0;
}
.form-template--parser .form {
  padding: 9px 0 32px;
}
.form-template--parser .form-template__col-l {
  padding: 0 17px 0 24px;
}
.form-template--parser .form-template__col-r {
  padding: 0 24px 0 14px;
}
.form-template--parser .area-style {
  padding: 15px 16px;
  height: 240px;
}
.text-select-extra .select-styled {
  font-size: 15px;
}
.download__link {
  display: inline-block;
  position: relative;
  padding: 0 0 0 33px;
  color: #218AEF;
  transition: all .3s;
}
.download__link:hover {
  text-decoration: none;
  opacity: .8;
}
.download__link {
  margin-right: 27px;

  @include respond-to(md) {
    margin: 0 15px 10px 0;
  }

  &:last-child {
    margin-right: 0;
  }
}
.download__links {
  @include respond-to(md) {
    padding-bottom: 9px !important;
  }
}
.download__link:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 24px;
  height: 24px;
  background: url(../images/download-save-circle-2.svg) center center/contain no-repeat;
}
.download__link.disabled:after {
  background: url(../images/download-save-circle.svg) center center/contain no-repeat;
}
.download__link.disabled {
  pointer-events: none;
  color: #A1A1A9;
}
.cell-style, .download__link {
  font: 14px/1.28 'DMSans-Regular';
}
.cell-style {
  color: #1C1C20;
}
.cell-style.text-secondary-grey {
  color: #8F8F9F;
}
.tabs__list {

  .nav-item {
    margin-right: 9px;

    &:last-child {
      margin-left: 0;
    }
  }

  .nav-link {
    font: 13px/1.3 'DMSans-Regular';
    color: #A1A1A9;
    border-radius: 4px;
    border: 1px solid #A1A1A9;
    padding: 8px 32px 8px 32px;
  }

  .nav-link.active {
    background: #218AEF;
    color: #FFFFFF;
    border-color: #218AEF;
  }

}
.tabs__table-row-heading {

  padding: 12px 5px;
  background: rgba(243, 244, 248, 0.5);

  .tabs__table-col {
    font: 12px/1.33 'DMSans-Bold';
    color: #8F8F9F;
  }

}
.tabs__table-row {
  padding: 19px 5px;
  border-bottom: 1px solid #DDDDE8;

  .tabs__table-col {
    color: #1C1C20;
    font: 14px/1.28 'DMSans-Regular';
  }
}
.tabs__table-row-total {
  padding: 19px 5px;

  .tabs__table-col {
    text-transform: capitalize;
    font: 14px/1.28 'DMSans-Bold';
    color: #1C1C20;
  }

}
.tabs__table-col {
  letter-spacing: 0.02em;
  text-transform: uppercase;

  @include respond-to(lg-screens) {
    flex: 0 0 24.5%;
    max-width: 24.5%;
  }
}
.graph {
  margin-bottom: 36px;
}
.tabs {
  padding: 15px 0;

  &__heading {
    font: 18px/1.27 DMSans-Medium;
    color: #1c1c20;
    padding-bottom: 21px;
    border-bottom: 1px solid rgba(205, 205, 211, .5);
    margin-bottom: 23px;

    @include respond-to(md) {
      padding-bottom: 11px;
      margin-bottom: 15px;
    }
  }

  &__list {

    @include respond-to(sm-tablets) {

      overflow-x: scroll;
      flex-wrap: nowrap;
      padding-bottom: 15px;

    }

  }
}
.tabs__block {
  padding: 22px 24px 33px;
  background: #FFFFFF;
  box-shadow: 0px 4px 8px rgba(28, 28, 32, 0.04);
  border-radius: 8px;
}
.tabs__table {
  @include respond-to(sm) {
    overflow-x: scroll;

    .tabs__table-row-md {
      width: 642px;
    }
  }

}
@media(min-width: 1300px) {
  .wrap-money-statistic {
    max-width: 61%;
    flex: 0 0 61%;
  }
  .wrap-referral-link {
    flex: 0 0 39%;
    max-width: 39%;
  }
}
































