@mixin visible {
  opacity: 1;
  visibility: visible;
}
@mixin hidden {
  opacity: 0;
  visibility: hidden;
}
@mixin pseudo-absolute {
  position: absolute;
  content: '';
}
@mixin absolute {
  position: absolute;
}
@mixin relative {
  position: relative;
}
@mixin d-block {
  display: block;
}
@mixin i-block {
  display: inline-block;
}
@mixin transition03 {
  transition: all .3s;
}
@mixin fullSize {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
@mixin respond-to($media) {
  @if $media == sm-screens-height {
    @media (max-height: 900px) {
      @content;
    }
  } @else if $media == landscape-phones {
    @media only screen and (min-width: 480px) and (max-width: 768px) and (orientation: landscape) {
      @content;
    }
  } @else if $media == xs-phones {
    @media (max-width: 350px) {
      @content;
    }
  } @else if $media == xs-phones-max {
    @media (max-width: 375px) {
      @content;
    }
  } @else if $media == sm-phones {
    @media (max-width: 375px) {
      @content;
    }
  } @else if $media == lg-phones {
    @media (max-width: 500px) {
      @content;
    }
  } @else if $media == sm-tablets {
    @media (max-width: 575px) {
      @content;
    }
  } @else if $media == sm {
    @media (max-width: 767px) {
      @content;
    }
  } @else if $media == md {
    @media (max-width: 991px) {
      @content;
    }
  } @else if $media == lg {
    @media (max-width: 1199px) {
      @content;
    }
  } @else if $media == laptops-md {
    @media (max-width: 1315px) {
      @content;
    }
  } @else if $media == laptops-lg {
    @media (max-width: 1440px) {
      @content;
    }
  } @else if $media == md-screens {
    @media (min-width: 992px) {
      @content;
    }
  } @else if $media == lg-screens {
    @media (min-width: 1200px) {
      @content;
    }
  }
    //@else if $media == lg-screens {
    //	@media (min-width: 1900px) { @content; }
    //}
  @else if $media == lg-screens-height {
    @media (min-height: 900px) {
      @content;
    }
  }
}
@mixin xl-screens {
  @media(min-width: 1280px) {
    @content;
  }
}
//@include respond-to(lg) {

//}
