/*100 Thin*/
/*200 Extra Light*/
/*300 Light*/
/*400 Normal*/
/*500 Medium*/
/*600 Semi Bold*/
/*700 Bold*/
/*800 Extra Bold*/
/*900 Ultra Bold*/

body,html,p {
	font: 16px/1.5 'DMSans-Regular', sans-serif;
}

.ff-bold {
	font-family: 'DMSans-Bold', sans-serif;
}
.ff-regular {
	font-family: 'DMSans-Regular', sans-serif;
}
.ff-medium {
	font-family: 'DMSans-Medium', sans-serif;
}



h1, .h1 {
	font: 24px/1.3 'DMSans-Bold', sans-serif;

	@include respond-to(sm-tablets) {
		font-size: 23px;
	}
}

h1.fz-default.dm-sans-bold {
	font-size: 24px;

	@include respond-to(sm-tablets) {
		font-size: 23px;
	}
}

h2, .h2 {
	font: 18px/1.3 'DMSans-Bold', sans-serif;
}

h2.fz-default.dm-sans-bold {
	font-size: 18px;
}

h3, .h3 {

}

h4, .h4 {

}

h5, .h5 {

}

h6, .h6 {

}


h1.dm-sans-regular {
	font-size: 36px;

	@include respond-to(sm-tablets) {
		font-size: 30px;
	}
}

h1.dm-sans-bold {
	font-size: 26px;
}

h2.dm-sans-bold {
	font-size: 20px;
}

h6.dm-sans-bold,h6.dm-sans-semibold {
	font-size: 14px;
}




