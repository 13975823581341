.modal-content-first {
  padding: 32px 31px 24px 32px;
  border-radius: 8px;
}



.modal-header-first {
  margin-bottom: 24px;

  &--mb {
    margin-bottom: 28px;
  }


}


.modal-title {
  font: 14px/1 'DMSans-Bold', sans-serif;
  text-transform: uppercase;
  color: #1C1C20;

  &--mb {
    margin-bottom: 20px;
  }
}

.close-first {
  top: 13px;
  right: 24px;

  &:focus {
    outline: none !important;
  }
}


.custom-select-main {
  font: 12px/1 'DMSans-Bold', sans-serif;
  text-transform: uppercase;
  color: #8F8F9F;
  border: 0;
  background: transparent;
  width: auto;
  padding: 0;

  &:focus {
    border: none;
    box-shadow: none;
  }

}


.custom-select-main {
  cursor: pointer;
  transition: all .3s ease;

  &:after {
    content: "";
    position: relative;
    display: inline-block;
    right: 2px;
    top: 2px;
    background: url("../images/Sorting.svg") center center no-repeat;
    width: 12px;
    height: 12px;
  }

  &:hover {
    color: #218aef;
  }

}

.modal-dialog-first {
  max-width: 513px;
  height: auto;
}


.rounded {
  label {
    position: relative;
    background: rgba(243, 244, 248, 0.7);
    border-radius: 4px;
    padding: 16px 16px 16px 46px;
    cursor: pointer;
  }

  .check-content-first__title {
    letter-spacing: 0;
    position: relative;
    top: 3px;
  }

}


.rounded label:before {
  content: '';
  position: absolute;
  width: 25px;
  height: 25px;
  left: 15px;
  top: 13px;
  background: #FFF;
  border: 1px solid #DDDDE8;
  border-radius: 50px;
}

.rounded label:after {
  content: '';
  position: absolute;
  width: 11px;
  height: 11px;
  left: 22px;
  top: 20px;
  background: #218aef;
  opacity: 0;
  border-radius: 50px;
}

.rounded input[type=radio]:checked + label:after {
  opacity: 1;
}

.rounded input[type=radio]:checked + label:before {
  border: 1px solid #218aef;
}

.rounded input[type=radio] {
  visibility: hidden;
  position: absolute;
}

.rounded-col {
  margin-bottom: 17px;

  &:nth-child(odd){
    padding-left: 0;
    padding-right: 8px;

    @include respond-to(sm-tablets) {
      padding: 0 15px;
    }



  }

  &:nth-child(even){
    padding-right: 0;
    padding-left: 10px;

    @include respond-to(sm-tablets) {
      padding: 0 15px;
    }
  }
}

.modal-body-first__content p {
  font: 14px/1.5 'DMSans-Medium', sans-serif;
  color: #8F8F9F;
  margin-bottom: 28px;
}

.btn--secondary {
  font: 14px/1 'DMSans-Medium', sans-serif;
  color: #FFFFFF;
  background: #218aef;
  padding: 16px 53px;
  transition: all .3s;

  &:hover {
    color: #218aef;
    background: #FFFFFF;
    border: 1px solid #218aef;
  }
}

.modal-body-first__form {
  margin-bottom: 13px;

 &--indentB {
  padding-bottom: 15px;
   margin-bottom: 38px;
   }
}


.price-blank {
  background: rgba(#F3F4F8,0.7);
  border-radius: 4px;
  padding: 16px;
  margin-bottom: 8px;

  @include respond-to(sm-tablets) {
    padding: 10px;
  }


  &__detail {
    font: 15px/1.4 'DMSans-SemiBold', sans-serif;
    color: #1C1C20;
    margin-bottom: 6px;
  }

  &__price {
    font: 14px/1.5 'DMSans-SemiBold', sans-serif;
    color: #8F8F9F;
  }

}


.btn--mini {
  font: 12px/1 'DMSans-SemiBold', sans-serif;
  text-transform: uppercase;
  color: #FFFFFF;
  background: #218aef;
  padding: 10px 16px;
  border: 1px solid transparent;

  @include respond-to(sm-tablets) {
    padding: 10px;
  }


  &:hover {
    color: #218aef;
    background: #FFFFFF;
    border: 1px solid #218aef;
  }
}


.modal-dialog-first--remove .modal-header-first h5 {
  font: 18px/1.7 'DMSans-Bold';
  color: #1C1C20;
  margin-bottom: 3px;
}

.modal-dialog-first--remove .modal-body-first .btn {
  padding: 15px 23px;
  font-size: 14px;
  text-transform: none;

}

.modal-dialog-first--remove.modal-content-first {
  padding: 32px 31px 28px 32px;
}

.btn--gray {
  background: #F3F4F8;
  color: #1C1C20;
}

.modal-dialog-first--remove .modal-header-first p {
  font: 14px/1.57 'DMSans-Regular';
  color: #A1A1A9;
}

.modal-dialog-first--remove .modal-header-first {
  margin-bottom: 10px;
}

.modal-dialog-first--remove.modal-dialog-first {
  max-width: 450px;
}


.modal-dialog-first--remove .close-first {
  top: 10px;
  right: 16px;
}



