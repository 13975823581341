.nav-list {

  li {
    margin-right: 18px;

    @include respond-to(sm-tablets) {
      margin-right: 12px;
    }

    @include respond-to(xs-phones) {
      margin-right: 9px;
    }


    &:last-child {
      margin-right: 0;
    }


    &:first-child {
      margin-right: 42px;


      @include respond-to(md) {
        margin-right: 12px;
      }

      @include respond-to(xs-phones) {
        margin-right: 9px;
      }
    }



    .account-item__btn {
      padding-right: 20px;
      outline: none !important;

      @include respond-to(xs-phones) {
        padding-right: 15px;
      }

      .account-item__arrow {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-40%);
        width: 16px;
        height: 16px;

        path {
          transition: all .2s ease;
        }

      }

    }

  }

  a {
    font: 14px/1 'DMSans-Regular', sans-serif;
    color: #A1A1A9;
    padding: 17px 0;
    transition: all .2s ease;

    &:hover {
      color: #218aef;
      text-decoration: none;


      .account-item__arrow path {
        stroke: #218aef;
      }

      .account-item__icon path {
        stroke: #218aef;
      }

    }


  }

}

.account-item.show {
  .account-item__btn {
    color: #218aef;

    .account-item__arrow path {
      stroke: #218aef;
    }
  }
}

.header-main {
  background: #FFFFFF;
  box-shadow: 0 4px 12px rgba(28, 28, 32, 0.06);
}

.side-line {
  padding-left: 16px;
  border-left: 1px solid #DDDDE8;

  @include respond-to(xs-phones) {
    padding-left: 6px;
  }
}

.account-item__data {
  line-height: 1.2;
  letter-spacing: 0.5px;
  padding-left: 8px;


  @include respond-to(md) {
    display: none !important;

  }
}

.account-item__img {
  display: inline-block;
  width: 30px;
  height: 30px;

}

.header-indent-main {
  margin-bottom: 46px
}

.dropdown-menu-style {
  padding: 0;

  .dropdown-item {
    font: 12px/1 'DMSans-Regular', sans-serif;
    text-transform: uppercase;
    color: #1C1C20;
    padding: 10px;
  }
}


.account-item__img img {
  border-radius: 50%;
  object-fit: contain;
}

.account-item__icon-wrap {
  padding: 0;

  a.account-item__btn {
    padding-left: 0;
    padding-right: 0;
  }
}

.btn.btn--nav {
  font-size: 13px;
  border: 1px solid #412BBC;
  border-radius: 90px;
  padding: 9px 23px;
  text-transform: capitalize;
  /* Main Violet */
  color: #412BBC;

  @include respond-to(sm) {
    padding: 7px;
  }
}

.account-item__icon path {
  transition: all .2s ease;
}

.account-item .dropdown-menu-style p {
  font-size: 14px;
  line-height: 1.5;
  color: #1C1C20;
}

.account-item .dropdown-menu-style {
  top: 10px !important;
  background: #FFFFFF;
  box-shadow: 0 4px 8px rgba(28, 28, 32, 0.04);
  border-radius: 8px;
  border: none;
  padding: 4px 0;

  &:after {
      content: "";
      position: absolute;
      top: -6px;
      left: 50%;
      -webkit-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
      transform: translateX(-50%);
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 10px 6px 10px;
      border-color: transparent transparent #fff transparent;
    }


}

.dropdown-menu-style--additional {
  padding: 20px 0 5px;
  width: 336px;
  transform: translate3d(-154px, 62px, 0px) !important;


  @include respond-to(md) {
    width: 240px;
    transform: translate3d(-90px,62px,0)!important;
  }

  p {
    padding-left: 24px;
    padding-right: 24px;

    @include respond-to(sm-tablets) {
      padding-left: 14px;
      padding-right: 14px;
    }
  }
}

.dropdown-menu-style--middle {
  width: 240px;
  transform: translate3d(-105px, 62px, 0px) !important;

  @include respond-to(md) {
    width: 220px;
    transform: translate3d(-123px,62px,0)!important;
  }




}

.account-item .dropdown-menu-style--additional:after {
  @include respond-to(md) {
    left: 25%;
  }


}



.account-item__circle:after {
  content:"";
  position: absolute;
  top: 16px;
  left: 12px;
  width: 10px;
  height: 10px;
  border: 2px solid #fff;
  border-radius: 50%;
  background: #EB1111;
  opacity: 0;
}

.account-item__circle.active:after {
  opacity: 1;
}

.dropdown-menu-style--middle .dropdown-item {
  position: relative;
  padding: 15px 10px 11px 40px;
  overflow: scroll;


  &:hover {
    .dropdown-menu-style__icon path {
      stroke:  #218aef;
    }
  }
}

.dropdown-menu-style__icon {
  position: absolute;
  left: 12px;
  top: 50%;
  transform: translateY(-38%);
  width: 20px;
  height: 20px;
}

.dropdown-menu-style__text {
  font-size: 14px;
  text-transform: capitalize;
}

.dropdown-menu-style__icon path,.dropdown-menu-style__text {
  transition: all .3s;
}


.dropdown-item:hover .dropdown-menu-style__text.text-red {
  color: #218aef;
}

.dropdown-menu-style--position {
  transform: translate3d(-45px,62px,0)!important;

  @include respond-to(md) {
    transform: translate3d(-95px,62px,0)!important;
  }

  @include respond-to(sm-tablets) {
    transform: translate3d(-100px,62px,0)!important;
  }
}

.account-item .dropdown-menu-style:after {
  @include respond-to(md) {
    display: none;
  }
}

.btn-img-responsive {
  display: none;

  @include respond-to(sm) {
    display: block;
    position: relative;
    top: 2px;
  }

}

.btn-text-responsive {
  display: block;

  @include respond-to(sm) {
    display: none;
  }
}



.old-sidebar .nav-list a {
  font: 12px/1 Metropolis-Regular,sans-serif;
  text-transform: uppercase;
  color: #1c1c20;
  padding: 10px 0;
}

.old-sidebar .account-item__data {
  text-transform: capitalize;
  letter-spacing: .5px;
}


.old-sidebar .dropdown-menu-style .dropdown-item {
  font: 12px/1 Metropolis-Regular,sans-serif;
  text-transform: uppercase;
  color: #1c1c20;
  padding: 10px;
}


.old-sidebar .nav-list li .account-item__btn:after {
  content: "";
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  background: url(../images/DownArrow.svg) center center no-repeat;
  width: 16px;
  height: 16px;
}


.old-sidebar .nav-list li .account-item__btn {
  padding-right: 22px;
  outline: 0!important;
}
